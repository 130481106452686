import styled from 'styled-components';
import IconCheck from '../../images/icon-check.png';

export const ParaStyle = styled.p`
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  margin: 0 0 40px 0;
  padding: 0;
  text-align: center;
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const ImageStyle = styled.img`
  width: 100%;
  height: auto;
  margin: 0 0 40px 0;
  border: 0;
  vertical-align: top;

  @media (max-width: 767px) {
    margin: 0 0 30px 0;
  }
  @media (max-width: 575px) {
    max-width: 100% !important;
    height: auto !important;
  }
`;
export const TitleStyle = styled.h3`
  text-align: center;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 32px;
  line-height: 37px;
  color: #00a6e8;
  @media (max-width: 1439px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
  text-align: center;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  color: #00a6e8;
`;

export const HeaderH2 = styled.h2`
  text-align: center;
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;

export const Container = styled.div`
  text-align: center;
`;
export const UnorderedList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
  text-align: left;
`;
export const ListItem = styled.li`
  padding: 0 0 15px 50px;
  background: url(${IconCheck}) no-repeat 0 1px;
  background-size: 30px auto;
  list-style: none;
  margin: 0;
  p {
    margin: 0;
    text-align: left;
  }
  @media (max-width: 767px) {
    padding-top: 1px;
    padding-left: 40px;
    background-position: 0 0;
    background-size: 25px auto;
  }
`;
export const HeaderH4 = styled.h4`
  padding: 0 0 15px 0;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: normal;
  @media (max-width: 1399px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
